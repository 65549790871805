
import { PayloadAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import sliceStateBases, { SliceState } from 'utils/enums/sliceBaseStates'
import TicketType from 'utils/enums/ticketTypes'
import { statusHandler, thunkActions } from 'utils/utils'
import { RootState } from 'app/store'
import { 
	TicketInitiatePayload, 
	getTicketInitiate, 
	getTicketOrderCheck, 
	postTicket
} from 'utils/api/ticketAPI'
import { 
	getGroupedArticlesCheck,
	getGroupedArticlesInitial, 
	getGroupedArticlesInitialProps, 
	GroupedArticlesCheckPayload, 
	GroupedArticlesRowsCriteria 
} from 'utils/api/ticketGroupedArticlesApi'
import dayjs from 'dayjs'
import { 
	NewTicketCreatePayload, 
	NewTicketInitiateResponse, 
	NewTicketOrderCheckPayload, 
	NewTicketOrderRow, 
	NewTicketRow, 
	OrderTicketOptions
} from 'types/INewTicket'
import { TicketGroupedArticlesInitiateResponse } from 'types/INewTicketGroupedArticles'

export const fetchNewTicketGroupedArticlesInitite = createAsyncThunk('newTicket/getGroupedArticlesInitial', async ({criteria, ticketType}: getGroupedArticlesInitialProps) => {
	return await getGroupedArticlesInitial(criteria, ticketType)
})
export const checkGroupedArticles = createAsyncThunk('newTicket/checkGroupedArticles', async (payload: GroupedArticlesCheckPayload) => await getGroupedArticlesCheck(payload))
export const fetchNewTicketInitite = createAsyncThunk('newTicket/getTicketInitiate', async (payload: TicketInitiatePayload) => await getTicketInitiate(payload))
export const sendNewTicket = createAsyncThunk('newTicket/sendNewTicket', async (payload: NewTicketCreatePayload) =>  await postTicket(payload))
export const checkOrderTicket = createAsyncThunk('newTicket/getTicketOrderCheck', async (payload: NewTicketOrderCheckPayload) => {
	const response = await getTicketOrderCheck(payload)

	return {
		error: response.error || null,
		ticketOptions: response.ticketOptions || [] 
	}
})

const actions = thunkActions(
	fetchNewTicketGroupedArticlesInitite,
	fetchNewTicketInitite,
	checkOrderTicket,
	sendNewTicket
)

export const initialCriteria: GroupedArticlesRowsCriteria = {
	search: '',
	fromDate: null,
	toDate: dayjs(new Date()).toISOString(), // Default to today
	deliveryAddressId: '',
	width: null,
	aspectRatio: null,
	diameter: null,
}

export interface NewTicketState {
	criteria: GroupedArticlesRowsCriteria | null
	ticketOrderCheck: {
		error: string | null
		ticketOptions: OrderTicketOptions[]
	} | null
	selectedTicketType: TicketType | null
	deliveryAddressId: number | null
	deliveryAddresses: {
		name: string
		value: number
	}[]
	ticketType: TicketType | null
	data: NewTicketOrderRow[] | null
	reason: string | null
	contactEmailAddress: string | null
	rows: NewTicketRow[]
}

export const initialNewTicketState: SliceState<'FEATURE', NewTicketState> = {
	...sliceStateBases.FEATURE,
	status: 'idle',
	data: null,
	criteria: initialCriteria,
	ticketOrderCheck: null,
	
	// Data used for submit
	selectedTicketType: null,
	deliveryAddressId: null,
	deliveryAddresses: [],
	reason: null,
	ticketType: null,
	contactEmailAddress: null,
	rows: [],
}

const newTicketSlice = createSlice({
	name: 'newTicket',
	initialState: initialNewTicketState,
	reducers: {
		resetAll: () => initialNewTicketState,
		resetTicketType: (state) => {
			state.selectedTicketType = null
			state.ticketOrderCheck = null
		},
		resetNewTicketCriteria: (state) => {
			state.criteria = initialCriteria
		},
		setNewTicketCriteria: (state, action) => {
			state.criteria = {
				...state.criteria,
				[action.payload.name]: action.payload.value,
			} as GroupedArticlesRowsCriteria
		},
		setReason: (state, action) => {
			state.reason = action.payload
		},
		setTicketType: (state, action) => {
			state.ticketType = action.payload
		},
		setContactEmailAddress: (state, action) => {
			state.contactEmailAddress = action.payload
		},
		setSelectedTicketType: (state, action) => {
			state.selectedTicketType = action.payload
		},
		setDeliveryAddressId: (state, action) => {
			state.deliveryAddressId = action.payload
		},
		setTicketQtys: (state, action) => {
			const orderRowId = action.payload.item.orderRowId
			const qty = action.payload.qty

			// Find the right row and update the quantity
			const rowToUpdate = state.rows.find(row => row.orderRowId === orderRowId)
			if (rowToUpdate) {
				rowToUpdate.quantity = qty
			}
		},
		setImageIds: (state, action) => {
			const orderRowId = action.payload.orderRowId
			const imageId = action.payload.imageitemId

			// Find the right row and update the imageIds
			const rowToUpdate = state.rows.find(row => row.orderRowId === orderRowId)
			if (rowToUpdate) {
				rowToUpdate.imageIds.push(imageId)
			}
		}
	},
	extraReducers: (builder) => {
		builder 
	    // For checking if order can be used for ticket
			.addCase(checkOrderTicket.fulfilled, (state, action) => {
				if (action.payload) {
					state.ticketOrderCheck = action.payload
					state.selectedTicketType = null
				}
			})
			.addCase(checkOrderTicket.rejected, (state) => {
				state.ticketOrderCheck = null
				state.selectedTicketType = null
			})
			// Get the order rows for ticket, and extras
			.addCase(fetchNewTicketInitite.fulfilled, (state, action: PayloadAction<NewTicketInitiateResponse>) => {
				state.data = action.payload

				state.rows = action.payload.rows.map((row: NewTicketRow) => {
					return {
						orderRowId: row.orderRowId,
						vatPercentage: row.vatPercentage,
						quantity: 0,
						imageIds: []
					}
				})
			})
			.addCase(fetchNewTicketInitite.rejected, (state) => {
				state.data = null
				state.rows = []
			})
			.addCase(fetchNewTicketGroupedArticlesInitite.fulfilled, (state, action: PayloadAction<TicketGroupedArticlesInitiateResponse>) => {
				state.data = action.payload
				state.selectedTicketType = TicketType.Preseason
				state.deliveryAddresses = action.payload.deliveryAddresses

				// When getting data build clean rows
				state.rows = action.payload.rows.map((row: NewTicketRow) => {
					return {
						orderRowId: row.orderRowId,
						vatPercentage: row.vatPercentage,
						quantity: 0,
						imageIds: []
					}
				})
			})
			.addCase(fetchNewTicketGroupedArticlesInitite.rejected, (state) => {
				state.data = null
				state.rows = []
			})
			.addMatcher(actions, statusHandler)
	}
})

export const { 
	resetAll,
	setNewTicketCriteria,
	setSelectedTicketType,
	resetNewTicketCriteria,
	resetTicketType,
	setTicketQtys,
	setImageIds,
	setDeliveryAddressId,
	setReason,
	setContactEmailAddress,
} = newTicketSlice.actions

export const selectNewTicketData = (state: RootState) => state.tickets.newTicket.data
export const selectNewTicketRows = (state: RootState) => state.tickets.newTicket.rows
export const selectNewTicketStatus = (state: RootState) => state.tickets.newTicket.status
export const selectNewTicketCriteria = (state: RootState) => state.tickets.newTicket.criteria
export const selectNewTicketDeliveryAddressId = (state: RootState) => state.tickets.newTicket.deliveryAddressId
export const selectNewTicketDeliveryAddresses = (state: RootState) => state.tickets.newTicket.deliveryAddresses
export const selectTicketOrderCheck = (state: RootState) => state.tickets.newTicket.ticketOrderCheck
export const selectOrderTicketOptions = (state: RootState) => state.tickets.newTicket.ticketOrderCheck?.ticketOptions
export const selectOrderTicketError = (state: RootState) => state.tickets.newTicket.ticketOrderCheck?.error
export const selectSelectedTicketType = (state: RootState) => state.tickets.newTicket.selectedTicketType
export const selectNewTicketReason = (state: RootState) => state.tickets.newTicket.reason
export const selectNewTicketContactEmailAddress = (state: RootState) => state.tickets.newTicket.contactEmailAddress


export default newTicketSlice.reducer
