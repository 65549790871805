import './ContactInformationModal.scss'
import React, { FunctionComponent } from 'react'
import { IconLink, Modal } from '@barnsten-it/gummidata-react-components'
import { useTranslation } from 'react-i18next'
import { useSelector, useDispatch } from 'react-redux'
import { selectSiteDataCompany } from 'features/siteData/siteDataSlice'
import { selectUserInfo } from 'features/user/userInfo/userInfoSlice'
import MainLabel from 'components/labels/MainLabel/MainLabel'
import IconValueRow, { IconValueRowProps } from 'components/IconValueRow/IconValueRow'
import {
	mdiFaceAgent,
	mdiEmail,
	mdiPhoneHangup,
	mdiInformationSlabCircleOutline,
} from '@mdi/js'
import { setShowContactInformationModal } from 'features/contactInformation/contactInformationSlice'
import { useNavigate } from 'react-router-dom'


interface ContactInformationModalProps {
	show?: boolean
}

const ContactInformationModal: FunctionComponent<ContactInformationModalProps> = ({
	show
}) => {
	
	const { t } = useTranslation()
	const dispatch = useDispatch()
	const userInfo = useSelector(selectUserInfo)
	const companyInfo = useSelector(selectSiteDataCompany)
	const navigate = useNavigate()
	
	const onClickHandler = () => {
		dispatch(setShowContactInformationModal(false))
	}

	if (!show) {
		return null
	}

	/**
	 * Sales contact data
	 */
	const salesData: IconValueRowProps[] = [
		{
			icon: mdiFaceAgent,
			value: userInfo?.salesPersonName
		},
		{
			icon: mdiEmail,
			value: (userInfo?.salesPersonEmailAddress) ? <a href={`mailto:${userInfo?.salesPersonEmailAddress}`}>{userInfo?.salesPersonEmailAddress}</a> : null
		},
		{
			icon: mdiPhoneHangup,
			value: (userInfo?.salesPersonTelephone) ? <a href={`tel:${userInfo?.salesPersonTelephone}`}>{userInfo?.salesPersonTelephone}</a> : null
		}
	]


	/**
	 * Support contact data
	 */
	const supportData: IconValueRowProps[] = [
		// {
		// 	icon: mdiLifebuoy,
		// 	value: <a href="https://ntgsd.atlassian.net/servicedesk/customer/portals" target="_blank" rel="noopener noreferrer">{t('common.serviceDesk')}</a>
		// },
		{
			icon: mdiEmail,
			value: (companyInfo?.emailaddress) ? <a href={`mailto:${companyInfo?.emailaddress}`}>{companyInfo?.emailaddress}</a> : null
		},
		{
			icon: mdiPhoneHangup,
			value: (companyInfo?.telephone) ? <a href={`tel:${companyInfo?.telephone}`}>{companyInfo?.telephone}</a> : null
		}
	]

	return (
		<Modal onClose={onClickHandler} closeOutside>
			<div className="ContactInformationModal">
				<MainLabel text={t('common.contactInformation')} noTopMargin />

				<p>
					{t('common.contactHelpText')}
				</p>

				<IconLink
					icon={mdiInformationSlabCircleOutline} 
					label={t('common.contactInformation')}
					onClick={() => navigate('/contact')}
				/>
				
				<div className="row">
					{salesData.some(item => item.value !== null && item.value !== undefined) &&
						<div className="col sales-section">
							<MainLabel text={t('common.salesContact')} />

							{salesData.map((data, index) => (
								<IconValueRow 
									key={index} 
									icon={data.icon} 
									value={data.value} 
								/>
							))}
						</div>
					}

					{supportData.some(item => item.value !== null && item.value !== undefined) &&
						<div className="col support-contact">
							<MainLabel text={t('common.supportContact')} />
					
							{supportData.map((data, index) => (
								<IconValueRow 
									key={index} 
									icon={data.icon} 
									value={data.value} 
								/>
							))}
						</div>
					}
				</div>
			</div>
		</Modal>
	)
}

export default ContactInformationModal