import React, { useMemo } from 'react'
import {
	useDispatch,
	useSelector
} from 'react-redux'
import {
	Filter,
	FilterContainer,
} from '@barnsten-it/gummidata-react-components'
import { useTranslation } from 'react-i18next'
import {
	selectFilterState,
	selectResult,
	setFilter,
} from '../premadeWheelPackageSearchSlice'
import {
	filterHidden,
	getAllArticles,
	isWinterFrictionTyre,
	isStuddedTyre,
	isStuddableTyre,
} from 'utils/helper'
import {
	sortAscending,
	filterOnlyUnique
} from 'utils/utils'
import { selectFeatureConfig } from 'features/app/settings/settingsSlice'
import frictionRegion from 'utils/enums/frictionRegion'
import mainGroups from 'utils/enums/mainGroups'

const PremadeWheelPackageFilter = () => {
	const { t } = useTranslation()
	const dispatch = useDispatch()
	const result = useSelector(selectResult)
	const filterState = useSelector(selectFilterState)
	const featureConfig = useSelector(selectFeatureConfig)

	const filterSettings = useMemo(() => {
		const articles = getAllArticles(result)

		const tyreMainGroups = [10, 20, 21, 22, 30, 31, 40, 41, 42]
		const winterTyreIds = [20, 21, 22, 40, 41, 42]
		const rimsMainGroups = [
			mainGroups.steelRim, 
			mainGroups.steelRimLV, 
			mainGroups.agroRim,
			mainGroups.steelRim, 
			mainGroups.aluminumRim
		]

		const tyres = articles.filter(a => tyreMainGroups.includes(a.mainGroupId))
		const rims = articles.filter(a => rimsMainGroups.includes(a.mainGroupId))
		
		const tyreBrands = [...new Set(tyres.map(a => a.brandName))].sort(sortAscending)
		const tyrePatterns = [...new Set(tyres.map(a => a.patternModelName))].sort(sortAscending)

		const rimBrands = [...new Set(rims.map(a => a.brandName))].sort(sortAscending)
		const rimModel = [...new Set(rims.map(a => a.patternModelName))].sort(sortAscending)

		const rimPrimaryColor = [...new Set(rims.map(a => a.primaryColor))]
			.filter(i => i !== null)
			.sort(sortAscending)
		const rimColor = [...new Set(rims.map(a => a.color))]
			.filter(i => i !== null)
			.sort(sortAscending)

		const hasWinterTyres = articles.some(a => winterTyreIds.includes(a.mainGroupId))

		const allDiameters = result?.flatMap(article => article.diameters)
		const uniqueDiameters = allDiameters?.filter(filterOnlyUnique).sort()

		const settings = [
			{
				name: t('searchFilter.diameter'),
				type: Filter.type.multipleSelect,
				items: uniqueDiameters,
				showMoreText: t('common.showMore'),
				condition: (item, selected) => {
					return item.diameters.some((a) => selected.indexOf(a) >= 0)
				},
			},
			{
				name: t('searchFilter.tyreType'),
				property: 'tyreTypeWinterWithStudFree',
				type: Filter.type.multipleSelect,
				hide: !hasWinterTyres || !featureConfig.showFrictionAsStudFree,
				items: [
					{ value: 1, text: t('searchFilter.studFree') },
					{ value: 2, text: t('searchFilter.studded') },
					{ value: 3, text: t('searchFilter.studdable') }
				],
				condition: (item, selected) => {
					const studFree = selected.includes(1) && item.articles.some(a => isWinterFrictionTyre(a.mainGroupId))
					const studded = selected.includes(2) && item.articles.some(a => isStuddedTyre(a.mainGroupId))
					const studdable = selected.includes(3) && item.articles.some(a => isStuddableTyre(a.mainGroupId))
					return studFree || studded || studdable
				},
			},
			{
				name: t('searchFilter.tyreType'),
				property: 'tyreTypeWinterWithFriction',
				type: Filter.type.multipleSelect,
				hide: !hasWinterTyres || featureConfig.showFrictionAsStudFree,
				items: [
					{ value: 1, text: t('searchFilter.nordicFriction') },
					{ value: 2, text: t('searchFilter.europeFriction') },
					{ value: 3, text: t('searchFilter.studded') },
					{ value: 4, text: t('searchFilter.studdable') }
				],
				condition: (item, selected) => {
					const frictionNordic = selected.includes(1) && item.articles.some(
						(a) => isWinterFrictionTyre(a.mainGroupId) && a.frictionRegion === frictionRegion.nordic
					)
					const frictionEuropean = selected.includes(2) && item.articles.some(
						(a) => isWinterFrictionTyre(a.mainGroupId) && a.frictionRegion === frictionRegion.european
					)
					const studded = selected.includes(3) && item.articles.some((a) => isStuddedTyre(a.mainGroupId))
					const studdable = selected.includes(4) && item.articles.some((a) => isStuddableTyre(a.mainGroupId))

					return frictionNordic || frictionEuropean || studded || studdable
				},
			},
			{
				name: t('searchFilter.carModel'),
				property: 'carTypeName',
				type: Filter.type.multipleSelect,
				sortEnabled: true,
				enableLimit: true,
				showMoreText: t('common.showMore'),
			},
			{
				name: t('searchFilter.tyreBrand'),
				property: 'tyreBrand',
				type: Filter.type.multipleSelect,
				items: tyreBrands,
				sortEnabled: true,
				enableLimit: true,
				showMoreText: t('common.showMore'),
				condition: (item, selected) => {
					return item.articles.some((a) => selected.indexOf(a.brandName) >= 0)
				},
			},
			{
				name: t('searchFilter.rimBrand'),
				property: 'rimBrand',
				type: Filter.type.multipleSelect,
				items: rimBrands,
				sortEnabled: true,
				enableLimit: true,
				hide: !featureConfig.rimBrand,
				showMoreText: t('common.showMore'),
				condition: (item, selected) => {
					return item.articles.some((a) => selected.indexOf(a.brandName) >= 0)
				},
			},
			{
				name: t('searchFilter.pattern'),
				property: 'pattern',
				type: Filter.type.multipleSelect,
				items: tyrePatterns,
				sortEnabled: true,
				enableLimit: true,
				showMoreText: t('common.showMore'),
				condition: (item, selected) => {
					return item.articles.some(
						(a) => selected.indexOf(a.patternModelName) >= 0
					)
				},
			},
			{
				name: t('searchFilter.rimModel'),
				property: 'rimModel',
				type: Filter.type.multipleSelect,
				items: rimModel,
				sortEnabled: true,
				enableLimit: true,
				showMoreText: t('common.showMore'),
				condition: (item, selected) => {
					return item.articles.some(
						(a) => selected.indexOf(a.patternModelName) >= 0
					)
				},
			},
			{
				name: t('common.parentColor'),
				property: 'primaryColor',
				type: Filter.type.multipleSelect,
				items: rimPrimaryColor,
				sortEnabled: true,
				enableLimit: true,
				showMoreText: t('common.showMore'),
				condition: (item, selected) => {
					return item.articles.some((a) => selected.indexOf(a.primaryColor) >= 0)
				},
			},
			{
				name: t('common.color'),
				property: 'color',
				type: Filter.type.multipleSelect,
				items: rimColor,
				enableLimit: true,
				sortEnabled: true,
				showMoreText: t('common.showMore'),
				condition: (item, selected) => {
					return item.articles.some((a) => selected.indexOf(a.color) >= 0)
				},
			},
			{
				name: t('searchFilter.properties'),
				group: [
					{
						name: t('common.tpms'),
						property: 'hasTPMS',
						type: Filter.type.checkbox,
					},
					{
						name: t('common.aluminumRim'),
						property: 'aluminumRim',
						type: Filter.type.checkbox,
						condition: (item, selected) => {
							return (
								selected && item.articles.some((a) => a.mainGroupId === 220)
							)
						},
					},
					{
						name: t('common.steelRim'),
						property: 'steelRim',
						type: Filter.type.checkbox,
						condition: (item, selected) => {
							return (
								selected && item.articles.some((a) => a.mainGroupId === 210)
							)
						},
					},
				],
			},
		]

		return settings.filter(filterHidden)
	}, [t, result, featureConfig])
	

	const handleFilterChange = (articleIds, filterState) => {
		dispatch(setFilter({ articleIds, filterState }))
	}

	if (!filterSettings || !result) return null

	return (
		<FilterContainer hasResult={!!result}>
			<Filter
				identifier="id"
				settings={filterSettings}
				items={result}
				label={t('common.searchFilter')}
				selected={filterState?.selected}
				onFilterChange={handleFilterChange}
			/>
		</FilterContainer>
	)
}

export default PremadeWheelPackageFilter
