import sliceStatus from 'utils/enums/sliceStatus'
import React from 'react'
import dayjs from 'dayjs'

/**
 * Returns the current domain
 * @returns
 */
export const getDomainName = () => {
	const hostname = document.location.hostname
	return hostname.substring(hostname.lastIndexOf('.', hostname.lastIndexOf('.') - 1) + 1)
}

/**
 * Return a correct time format
 * @param {date} value
 * @param {string} shortDateFormat
 * @returns Date Formated string with hours and minuts
 */
export const timeformat = (time, shortDateFormat, t) => {
	var isYesterday = require('dayjs/plugin/isYesterday')
	var isToday = require('dayjs/plugin/isToday')

	dayjs.extend(isToday)
	dayjs.extend(isYesterday)

	const date = dayjs(time)

	var string = ''
	if (date.isToday()) {
		string += t('common.todayAt')
	} else if (date.isYesterday()) {
		string += t('common.yesterdayAt')
	} else {
		string += dayjs(date).format(shortDateFormat)
	}
	return string + ' ' + dayjs(date).format('HH:mm')
}

/**
 * Random string generator
 * @param {*} number 
 * @returns 
 */
export const randomizer = (length) => {
	var result = ''
	var characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789'
	var charactersLength = characters.length

	for (var i = 0; i < length; i++) {
		result += characters.charAt(Math.floor(Math.random() * charactersLength))
	}
	return result
}


/**
 * Secure Random number generator
 * @returns Number
 */
export const ranomizerNumber = () => {
	const crypto = window.crypto || window.msCrypto
	var array = new Uint32Array(1)
	crypto.getRandomValues(array) 
	return array[0]
}


/**
 * Make string into componets that splits on linebreak '\n'
 * @param {*} text 
 * @returns 
 */
export const LineBreakText = ({ string, className = null }) => {
	if (string.includes('\n')) {
		return string.split('\n').map((str, index) => <p className={className} key={index}>{str}</p>)
	}
	return string
}

/**
 * To check for previous value this method can be used
 * https://reactjs.org/docs/hooks-faq.html#how-to-get-the-previous-props-or-state
 * @param {any|null} value 
 * @returns 
 */
export const usePrevious = (value) => {
	const ref = React.useRef()
	React.useEffect(() => {
		ref.current = value
	})
	return ref.current
}

export const startsWith = (str, word) => {
	return str.lastIndexOf(word, 0) === 0
}

export const endsWith = (str, suffix) => {
	return str.indexOf(suffix, str.length - suffix.length) !== -1
}

export const getQueryVariable = (variable) => {
	var query = window.location.search.substring(1)
	var vars = query.split('&')
	for (var i = 0; i < vars.length; i++) {
		var pair = vars[i].split('=')
		if (pair[0] === variable) { return pair[1] }
	}
	return (false)
}


/**
 * Catch all actions related to API thunk
 * @param {object} thunks - provide thunks
 * @returns action
 */
export const thunkActions = (...thunks) => {
	let types = []

	thunks.forEach(thunk => {
		types.push(thunk.pending().type)
		types.push(thunk.fulfilled().type)
		types.push(thunk.rejected().type)
	})

	return (action) => {
		return types.indexOf(action.type) >= 0
	}
}


/**
 * Update common status state
 * @param {object} state - Redux state
 * @param {object} action - Redux action
 */
export const statusHandler = (state, action) => {
	// Reset short-hand status properties
	state.isLoading = false
	state.hasSucceeded = false
	state.hasFailed = false

	if (action.type.endsWith('pending')) {
		state.isLoading = true
		state.status = sliceStatus.loading
		state.error = null
	} else if (action.type.endsWith('fulfilled')) {
		state.hasSucceeded = true
		state.status = sliceStatus.success
	} else if (action.type.endsWith('rejected')) {
		state.hasFailed = true
		state.status = sliceStatus.failed
		state.error = action.payload || (action.error && action.error.message) || action.error
	}
}


/**
 * isEmpty check
 * @param {*} obj
 * @return {*} 
 */
export const isObjectEmpty = (obj) => {
	for (var prop in obj) {
		if (Object.prototype.hasOwnProperty.call(obj, prop)) {
			return false
		}
	}

	return JSON.stringify(obj) === JSON.stringify({})
}


/**
 * Throttle eventlistener
 *
 * @param {*} func
 * @param {*} limit
 * @return {*} 
 */
export const throttle = (func, limit) => {
	let lastFunc
	let lastRan
	return function () {
		const context = this
		const args = arguments
		if (!lastRan) {
			func.apply(context, args)
			lastRan = Date.now()
		} else {
			clearTimeout(lastFunc)
			lastFunc = setTimeout(function () {
				if ((Date.now() - lastRan) >= limit) {
					func.apply(context, args)
					lastRan = Date.now()
				}
			}, limit - (Date.now() - lastRan))
		}
	}
}


/**
 * If value inserted is Null return empty string instead
 * 
 * @param {*} value 
 * @returns 
 */
export const ifNullBeEmptyString = (value) => {
	if (value == null) {
		return ''
	}
	return value
}

/**
 * Get midnight from date
 */
export const getMidnight = (day) => {
	const date = new Date(day)
	date.setMilliseconds(0)
	date.setSeconds(0)
	date.setMinutes(0)
	date.setHours(0)
	return date
}

/**
 * Get date tomorrow
 * @param {*} date 
 * @returns 
 */
export const getTomorrow = () => {
	const oneDay = 1000 * 60 * 60 * 24
	const midnightTonight = getMidnight(new Date())
	const midnightTomorrow = new Date(midnightTonight.getTime() + oneDay)

	return midnightTomorrow
}

/**
 * Check is date is today
 * @param {*} date 
 */
export const isToday = (date) => {
	return new Date(date).toDateString() === new Date().toDateString()
}

/**
 * Check if date is tomorrow
 * @param {*} date 
 */
export const isTomorrow = (date) => {
	return new Date(date).toDateString() === getTomorrow().toDateString()
}

export const filterOnlyUnique = (value, index, self) => {
	return self.indexOf(value) === index
}

/**
 * Retrun array with object that have unique values in the object. 
 * @param {*} array 
 * @param {*} keyToCheck 
 * @returns 
 */
export const filterOnlyUniqueObjs = (array, keyToCheck) => {
	var newArray = []
	for (let index = 0; index < array.length; index++) {
		if (!newArray.some(item => item[keyToCheck] === array[index][keyToCheck])) {
			newArray.push(array[index])
		}
	}
	return newArray
}


export const sortAscending = (a, b) => {
	if (a < b) { return -1 }
	if (a > b) { return 1 }
	return 0
}

/**
 * Sort collection by property
 * @param {string} property 
 * @param {boolean} ascending 
 * @returns 
 */
export const sortByProperty = (property, ascending = true) => {
	const sortOrder = ascending ? 1 : -1
	return (a, b) => {
		var result = (a[property] < b[property]) ? -1 : (a[property] > b[property]) ? 1 : 0
		return result * sortOrder
	}
}

/**
 * Set localstorage item
 * @param {string} name 
 * @param {value} value 
 * @returns 
 */
export const setLocalStorageItem = (name, value) => {
	try {
		return localStorage.setItem(name, value)
	} catch (e) {
		let errName = e.name
		if (
			typeof errName == 'string' &&
			errName.includes('NS_ERROR')
		) {
			localStorage.clear()
			sessionStorage.clear()
			window.location.reload(true)
		} else {
			throw e
		}
	}
}

/**
 * 
 * @param {string} name 
 * @returns 
 */
export const getLocalStorageItem = (name) => {
	try {
		return localStorage.getItem(name)
	} catch (e) {
		let errName = e.name
		if (
			typeof errName == 'string' &&
			errName.includes('NS_ERROR')
		) {
			localStorage.clear()
			sessionStorage.clear()
			window.location.reload(true)
		} else {
			throw e
		}
	}
}

/**
 * Get property name from object by value
 * @param {*} object 
 * @param {*} value 
 * @returns 
 */
export const getPropertyNameFromValue = (object, value) => {
	let returnValue = null
	Object.keys(object).forEach((key, index) => {
		if (object[key] === value) {
			returnValue = key
		}
	})

	return returnValue
}

/**
 * Add or update data in collection
 * @param {*} collection 
 * @param {*} data 
 * @param {*} predicate 
 */
export const addOrUpdate = (collection, data, predicate) => {
	let existingIndex = collection.findIndex(predicate)
	if (existingIndex === -1)
		collection.push(data)
	else {
		collection[existingIndex] = data
	}
}

export const nl2br = (str, replaceMode, isXhtml) => {
	const breakTag = (isXhtml) ? '<br />' : '<br>'
	const replaceStr = (replaceMode) ? '$1' + breakTag : '$1' + breakTag + '$2'
	return (str + '').replace(/([^>\r\n]?)(\r\n|\n\r|\r|\n)/g, replaceStr)
}

export const stringToRegex = (s, m) => {
	m = s.match(/^([/~@;%#'])(.*?)\1([gimsuy]*)$/)
	return m ? new RegExp(m[2], m[3].split('').filter((i, p, s) => s.indexOf(i) === p).join('')) : new RegExp(s)
}

export const capitalizeFirstLetter = (string) => {
	if (
		typeof string !== 'string' ||
		string.length === 0
	) return string
	let firstChar = string.charAt(0)
	return string.replace(firstChar, firstChar.toUpperCase())
}

export const getCurrentISOString = () => {
	return dayjs(Date.now()).toISOString()
}

export const ensureObject = (object) => {
	return ((
		typeof object == 'object' &&
		object !== null
	) ? object : {})
}

/**
 * Converts a string into a number or,
 * if it can't, turns it into `null` instead of `NaN`.
 * 
 * Ignores empty strings, returning them back.
 */
export const stringToNumOrNull = (value) => {
	if (typeof value === 'number') return value
	else if (typeof value !== 'string') return null
	else if (value === '') return value

	value = Number(value)
	return isNaN(value) ? null : value
}

/**
 * Determines whether value is nullish.
 */
export const isNullish = (value) => {
	return (
		value === null ||
		value === undefined
	)
}

export const scrollToTop = () => {
	window.scrollTo({ top: 0, behavior: 'smooth' })
}

export const scrollTo = (
	top,
) => {
	window.scrollTo({ top, behavior: 'smooth' })
}

export const scrollBy = (
	top,
) => {
	window.scrollBy({ top, behavior: 'smooth' })
}


/**
 * Sluggify string 
 * 
 * @param {*} str 
 * @returns slug strgin
 */
export const slugify = (str) => {
	const slug = str
		.trim()
		.toLowerCase()
		.replace(/[^\w\s-]/g, '') // remove non-word characters (except hyphens and spaces)
		.replace(/[\s_-]+/g, '-') // replace spaces and underscores with a single hyphen
		.replace(/^-+|-+$/g, '') // remove leading/trailing hyphens
	return slug
}