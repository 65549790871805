import apiClient from './apiClient'

/**
 * Get new invoices
 * @param {object} payload Form data for application
 * @returns 
 */
export const fetchNewInvoices = async () => await apiClient.get('/api/InvoicesNew')

/**
 * Validate new invoices has been printed
 * @param {object} payload Form data for application
 * @returns 
 */
export const putNewInvoicesValidatedPrinting = async (payload) => await apiClient.post('/api/InvoicesNew', payload)