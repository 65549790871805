import apiClient from './apiClient'

/**
 * Fetch customer cart
 * @returns cart model
 */
export const getCart = async (languageCode) => await apiClient.get('/api/cart', { languageCode })

/**
 * Save cart data
 * @param {object} data (deliveryAddressId, wayOfDeliveryId, notifyReceiver, partiallyDelivery, awaitNextOrder, lastChanged)
 * @returns 
 */
export const putCartData = async (data) => await apiClient.put(`/api/cart?languageCode=${data.languageCode}`, data)

/**
 * Create order from cart
 * @param {object} data (paymentMethod, languageCode, lastChanged)
 * @returns {object} (orderIds, notAvailableRowIds, wayOfDeliveryId, customerStockOrder, error, externalPayment, redirectUrl)
 */
export const postCart = async (data) => await apiClient.post('/api/cart', data)

/**
 * Clear whole cart
 * @returns 
 */
export const deleteCart = async () => await apiClient.delete('/api/cart/')

/**
 * Save row data
 * @param {number} rowId 
 * @param {array} operations 
 * @param {string} languageCode 
 * @param {string} lastChanged 
 * @returns 
 */
export const patchCartRow = async (rowId, operations, languageCode, lastChanged) => await apiClient.patch(`/api/cartRow/${rowId}?lastChanged=${lastChanged}&languageCode=${languageCode}`, operations)

export const putCartRow = async (id, languageCode, payload) => await apiClient.put(`/api/CartRow/${id}?languageCode=${languageCode}`, payload)

/**
 * Delete row item
 * @returns
 */
export const deleteRowItem = async (rowId, languageCode, lastChanged) => await apiClient.delete('/api/CartRow/' + rowId, { languageCode, lastChanged })

/**
 * Add wheel package to cart
 * @param {object} packageInfo (carModel, licensePlate, quantity, packageQuantity, wheelPackageId, assembly, tpms)
 * @param {array} packageRows (articleId, kitset, isMatched, netPrice)
 * @param {string} languageCode
 * @returns 
 */
export const postWheelPackage = async (packageInfo, packageRows) => await apiClient.post('/api/CartPackage', {
	...packageInfo,
	rows: packageRows
})

/**
 * Add articles to cart
 * @param {*} articles 
 * @returns 
 */
export const postCartRows = async (articles) => await apiClient.post('/api/cartrows/', articles)


/**
 * Get delivery addresses for cart
 * @returns 
 */
export const getCartDeliveryAddresses = async () => await apiClient.get('/api/CartDeliveryAddresses')

/**
 * Get way of deliveries
 * @returns 
 */
export const getWayOfDeliveries = async () => await apiClient.get('/api/CartWayOfDeliveries')

/**
 * Get order info for payment
 * @param {number} paymentId 
 * @returns 
 */
export const getOrderInfo = async (paymentId) => await apiClient.get('/api/OrderCartInfo/' + paymentId)
