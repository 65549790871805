import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { getAccessoryTypes } from 'utils/api/articleSearchAPI'
import { statusHandler, thunkActions } from 'utils/utils'
import { loggedOut } from 'utils/redux/commonActions'
import mainGroups from 'utils/enums/mainGroups'

export const searchAccessoryTypes = createAsyncThunk('accessoryType/search', async () => await getAccessoryTypes(mainGroups.accessoriesCar))

const searchAccessoryTypesAction = thunkActions(searchAccessoryTypes)

const accessoryTypes = createSlice({
	name: 'accessoryTypesSearch',
	initialState: {
		status: 'idle',
		result: [],
	},
	extraReducers: builder => {
		builder
			.addCase(loggedOut, state => {
				state.status = 'idle'
				state.result = []
			})
			.addCase(searchAccessoryTypes.pending, state => {
				state.result = []
			})
			.addCase(searchAccessoryTypes.fulfilled, (state, action) => {
				state.result = action.payload
			})
			.addMatcher(searchAccessoryTypesAction, statusHandler)
	}
})

export const selectStatus = state => state.accessoryTypes.status
export const selectResult = state => state.accessoryTypes.result

export default accessoryTypes.reducer