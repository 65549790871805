import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { getTpmsByMainGroupId } from 'utils/api/articleSearchAPI'
import mainGroups from 'utils/enums/mainGroups'
import { createResultSelector } from 'utils/helper'
import { loggedOut } from 'utils/redux/commonActions'
import { statusHandler, thunkActions } from 'utils/utils'

export const searchTpms = createAsyncThunk('truckTpmsSearch/searchTpms', async () => await getTpmsByMainGroupId(mainGroups.tpmsTbr))
const searchActions = thunkActions(searchTpms)

const initialState = {
	status: 'idle',
	result: null,
	filterIds: null,
	filterState: null,
	sortBy: null,
	ascending: null
}

const truckTpmsSearch = createSlice({
	name: 'truckTpmsSearch',
	initialState,
	reducers: {
		setFilter: (state, action) => {
			state.filterIds = action.payload.articleIds
			state.filterState = action.payload.filterState
		},
		sortResult: (state, action) => {
			state.sortBy = action.payload.sortBy
			state.ascending = action.payload.ascending
		},
		setVehicle: (state, action) => {
			state.criteria.tecDocId = action.payload.tecDocId
			state.criteria.license = action.payload.license
		},
		resetCriteria: (state) => {
			state.criteria = {
				...initialState.criteria
			}
		}
	},
	extraReducers: builder => {
		builder
			.addCase(loggedOut, () => initialState)
			.addCase(searchTpms.pending, (state) => {
				state.result = null
				state.filterIds = null
				state.filterState = null
			})
			.addCase(searchTpms.fulfilled, (state, action) => {
				state.result = action.payload
			})
			.addCase(searchTpms.rejected, (state) => {
				state.result = []
			})
			.addMatcher(searchActions, statusHandler)
	}
})

export const { setFilter, sortResult, resetCriteria, setVehicle } = truckTpmsSearch.actions

export const selectStatus = state => state.search.tpms.truckTpms.status
export const selectResult = state => state.search.tpms.truckTpms.result
export const selectFilterIds = state => state.search.tpms.truckTpms.filterIds
export const selectFilterState = state => state.search.tpms.truckTpms.filterState
export const selectSortBy = state => state.search.tpms.truckTpms.sortBy
export const selectAscending = state => state.search.tpms.truckTpms.ascending
export const selectCritiera = state => state.search.tpms.truckTpms.criteria

export const selectProcessedResult = createResultSelector(selectResult, selectFilterIds, selectSortBy, selectAscending)

export default truckTpmsSearch.reducer
