const mainGroups = {
	summerTyrePassengerCar: 10,
	summerTyreC: 30,
	summerTyreInch: 48,
	
	allSeasonTyre: 15,
	allSeasonLightTruck: 35,
	
	winterTyreFriction: 20,
	winterTyreStudded: 21,
	winterTyreStuddable: 22,
	winterTyreFrictionC: 40,
	winterTyreCStudded: 41,
	winterTyreCStuddable: 42,
	winterTyreInch: 49,

	lvTyre: 50,
	tractor: 66,
	tyreEM: 80,
	tyreMC: 85,
	tyreATV: 88,
	tyreFolkRace: 90,
	forklift: 95,
	smallTyre: 96,

	steelRim: 210,
	steelRimLV: 215,
	agroRim: 216,
	aluminumRim: 220,
	battery: 300,

	oil: 400,

	accessoriesCar: 500,
	accessoriesIndustry: 501,
	accessoriesBattery: 502,
	accessoriesOil: 503,
	accessoriesTruck: 504,
	tpmsPcr: 510,
	tpmsTbr: 511,
	tubes: 520,
	tubesPcr: 521,
	tubesIndustry: 522,
	tubesMoto: 523,
	other: 550,
} as const

export default mainGroups

export type MainGroupType = keyof typeof mainGroups
