import {
	Articles,
	ArticleStocks,
	CarInfo,
	DiameterArticles,
	GroupNameArticles,
	MainGroupIdArticles,
	StockQuantity,
} from '../../types/Responses'
import apiClient from './apiClient'

export const getTyres = async (
	query: TyresQuery,
): Promise<TyresRes> => {
	return await apiClient.get('/api/Tyres', {
		...query,
		tyreType: query.tyreType ?? 1,
		languageCode: query.languageCode ?? 'SV',
	})
}

export const getTyresByProperties = async (
	width: TyresQuery['width'],
	aspectRatio: TyresQuery['aspectRatio'],
	diameter: TyresQuery['diameter'],
	secondaryWidth: TyresQuery['secondaryWidth'],
	secondaryAspectRatio: TyresQuery['secondaryAspectRatio'],
	secondaryDiameter: TyresQuery['secondaryDiameter'],
	articleText: TyresQuery['articleText'],
	motoBrand: TyresQuery['motoBrand'],
	motoEngineCapacity: TyresQuery['motoEngineCapacity'],
	motoModel: TyresQuery['motoModel'],
	vehicleId: TyresQuery['vehicleId'],
	tyreType: TyresQuery['tyreType']=1,
	languageCode: TyresQuery['languageCode']='SV',
) => {
	return await getTyres({
		width,
		aspectRatio,
		diameter,
		secondaryWidth,
		secondaryAspectRatio,
		secondaryDiameter,
		articleText,
		motoBrand,
		motoEngineCapacity,
		motoModel,
		vehicleId,
		tyreType,
		languageCode,
	})
}

export const getRims = async (
	query: RimsQuery,
): Promise<RimsRes> => {
	return await apiClient.get('/api/Rims', query)
}

export const getRimsBySize = async (
	boltPattern: number,
	boltCircle: number,
	diameter: number,
	width: number,
	articleText: string,
	rimType: number,
	languageCode: string
) => {
	return await getRims({
		boltPattern,
		boltCircle,
		diameter,
		width,
		articleText,
		rimType,
		languageCode
	})
}

export const getRimsByVehicleId = async (
	vehicleId: string,
	licensePlate: string,
	/**
	 * Enum `searchRimType`.
	 */
	rimType: number,
	languageCode: string
) => {
	return await getRims({
		vehicleId,
		licensePlate,
		rimType,
		languageCode
	})
}

export const getRimsByCarModel = async (
	carModel: string,
	brandId: number,
	/**
	 * Enum `searchRimType`.
	 */
	rimType: number,
	languageCode: string
) => {
	return await getRims({
		carModel,
		brandId,
		rimType,
		languageCode
	})
}

export const getAccesories = async (
	query: AcessoriesQuery,
): Promise<Accessory> => {
	return await apiClient.get('/api/Accessories', query)
}

export const getAccessoriesByProperties = async (
	articleText: string | null,
	accessoryType: number,
	mainGroupId: number,
	diameter: number | null,
	width: number | null,
	aspectRatio: number | null
) => {
	return await getAccesories({
		articleText,
		accessoryType,
		mainGroupId,
		diameter,
		width,
		aspectRatio		
	})
}

export const getAccessoryTypes = async (
	mainGroupId: number,
): Promise<AccessoryType[]> => {
	return await apiClient.get(`/api/AccessoryTypes/${mainGroupId}`)
}

export const getTpms = async (
	query: TpmsQuery,
): Promise<TpmsRes> => {
	return await apiClient.get('/api/TPMS', query)
}

export const getTpmsByMainGroupId = async (
	mainGroupId: number,
) => {
	return await getTpms({
		mainGroupId,
		includeTools: true,
	})
}

export const getTpmsByVehicleId = async (
	vehicleId: string,
) => {
	return await getTpms({
		vehicleId,
	})
}

export const getTpmsByArticleText = async (
	articleText: string,
) => {
	return await getTpms({
		articleText,
	})
}

export const getTpmsByCar = async (
	carBrand: string,
	carModel: string,
	carYear: string,
) => {
	return await getTpms({
		carBrand,
		carModel,
		carYear,
	})
}

export const getWheels = async (
	query: WheelsQuery,
): Promise<WheelsArticle> => {
	return await apiClient.get('/api/Wheels', query)
}

export const getWheelRimsByVehicleIdId = async (
	vehicleId: string,
	licensePlate: string,
	languageCode: string
) => {
	return await getWheels({
		vehicleId,
		licensePlate,
		languageCode
	})
}

export const getWheelRimsByCarModel = async (
	carModel: string,
	brandId: number,
	languageCode: string
) => {
	return await getWheels({
		carModel,
		brandId,
		languageCode
	})
}

/**
 * Fetch tyres for wheel packages.
 */
export const getWheelsTyres = async (
	query: WheelsTyresQuery,
): Promise<WheelsTyresRes> => {
	return await apiClient.get('/api/WheelsTyres', query)
}

export const getWheelsTyresByProperties = async (
	vehicleId: WheelsTyresQuery['vehicleId'],
	diameter: WheelsTyresQuery['diameter'],
	width: WheelsTyresQuery['width'],
	secondaryDiameter: WheelsTyresQuery['secondaryDiameter'],
	secondaryWidth: WheelsTyresQuery['secondaryWidth'],
	tyreType: WheelsTyresQuery['tyreType'],
	languageCode: WheelsTyresQuery['languageCode'],
) => {
	return await getWheelsTyres({
		vehicleId,
		diameter,
		width,
		secondaryDiameter,
		secondaryWidth,
		tyreType,
		languageCode,
	})
}

/**
 * Get tpms price and if vehicle has tpms support.
 */
export const getWheelsTpms = async (
	query: WheelsTpmsQuery,
): Promise<WheelsTpms> => {
	return await apiClient.get('/api/WheelsTPMS', query)
}

export const getWheelsTpmsByVehicleId = async (
	vehicleId: string,
) => {
	return await getWheelsTpms({
		vehicleId,
	})
}

export const getAlternativeDimensions = async (
	query: AlternativeDimensionsQuery,
): Promise<AlternativeDimension[]> => {
	return await apiClient.get('/api/AlternativeDimensions', query)
}

export const getAlternativeDimensionsByProperties = async (
	width: AlternativeDimensionsQuery['width'],
	aspectRatio: AlternativeDimensionsQuery['aspectRatio'],
	diameter: AlternativeDimensionsQuery['diameter'],
	tyreType: AlternativeDimensionsQuery['tyreType'],
) => {
	return await getAlternativeDimensions({
		width,
		aspectRatio,
		diameter,
		tyreType,
	})
}

/**
 * Get premade wheelpackages.
 */
export const getWheelPackages = async (
	query: WheelPackagesQuery,
): Promise<WheelPackagesRes> => {
	return await apiClient.get('/api/ArticlePackages', query)
}

/**
 * Get tyre types for existing pre-made packages.
 */
export const getWheelPackageTyreTypes = async (): Promise<WheelPackageTyreTypes> => {
	return await apiClient.get('/api/ArticlePackageTyreTypes')
}

/**
 * Email excel list for existing pre-made packages.
 */
export const postListOnEmail = async (
	query: WheelPackageExcelListQuery
) => {	
	return await apiClient.post('/api/ArticlePackageExcel/', query)
}

export const getBatteries = async (
	query: BatteriesQuery,
): Promise<BatteriesRes> => {
	return await apiClient.get('/api/Batteries', query)
}

/**
 * Get available battery voltage.
 */
export const getBatteryVoltage = async (): Promise<BatteryVoltage> => {
	return await apiClient.get('/api/BatteryVoltage')
}

/**
 * Get Quick search items
 * @param payload
 * @returns {Promise<QuickSearchArticle[]>} // array of articles
 */
export const getQuickSearch = async (payload: QuickSearchPayload): Promise<QuickSearchArticle[]> => await apiClient.get('/api/QuickSearch', payload)

/**
 * Get All Products Search items
 * @param payload
 * @returns {Promise<AllProductSearchPayload[]>} // array of articles
 */
export const getAllProductsSearch = async (payload: AllProductsSearchPayload): Promise<QuickSearchArticle[]> => await apiClient.get('/api/QuickSearch', payload) // Should be its own endpoint

/**
 * IWebArticleModel in API
 */
 interface ArticleModel {
	id: number
	mainGroupId: number
	subGroupId: number
	quantityAvailable: number
	quantityIncoming: number
	quantityCustomerStock: number
	quantityExternal: number
	imageId: number
	price: number
	netPrice: number
	retailPrice: number
	priceVatRecycleFee: number
	margin: number | null
	stopTime: string | null
	estimatedDelivery: string | null
	externalDeliveryTime: string | null
	externalStockCountryCode: string
	customStyling: boolean
	topSorting: boolean
	allowOrder: boolean
	vehicleType: string
	category: string
	availability: number
	stockQuantities: StockQuantity[]
}

export interface QuickSearchPayload {
	searchQuery: string
	limit?: number
	languageCode: string
}

export interface AllProductsSearchPayload {
	searchQuery: string
	limit?: number
	languageCode: string
}

export interface QuickSearchArticle extends ArticleModel {
	articleText: string
	brandName: string
	modelName: string
	subGroupId: number
	recommendedPrice: number | null
	fuelEffiency: string | null
	wetGrip: string | null
	noiceClass: string | null
	noiceValue: number | null
	frictionRegion: number
	position: number | null
	width: number
	diameter: number
	offset: number
	centerBore: number
	numberOfBolts: number
	boltCircle: string
}

export interface TyresQuery {
	width: number
	aspectRatio: number
	diameter: number
	/**
	 * Secondary size.
	 */
	secondaryWidth?: number
	/**
	 * Secondary size.
	 */
	secondaryAspectRatio?: number
	/**
	 * Secondary size.
	 */
	secondaryDiameter?: number
	articleText: string
	motoBrand: string,
	motoEngineCapacity: string,
	motoModel: string,
	/**
	 * Search by car.
	 */
	vehicleId: string
	/**
	 * Enum `tyreType` or other values based on `maingroupid`.
	 */
	tyreType: number
	/**
	 * Used for article texts, studded and so on.
	 */
	languageCode: string
}

export interface TyreArticle extends ArticleModel {
	recommendedPrice: number | null
	minimumQuantity: number
	articleText: string
	fuelEffiency: string
	wetGrip: string
	noiceClass: string
	noiceValue: string | null
	weight: number
	recyclingFee: number
	dotYear: number
	width: number
	aspectRatio: number
	diameter: number
	brandId: number
	brandName: string
	patternName: string
	hasLogo: boolean
	loadIndex: number
	speedIndex: string
	runFlat: boolean
	extraLoad: boolean
	mudAndSnow: boolean
	treePMSF: boolean
	isOE: boolean
	oeMarking: string
	axle: number | null
	position: number | null
	usageTypeText: string | null
	plyRating: unknown | null
	frictionRegion: number
	tyreCasingName: string | null
	tyreCasingId: number | null
	eprelId: number
	points: number | null
	electric: boolean
	sealInside: boolean
	noiseCancelling: boolean
}

export type TyresRes = ArticleStocks<MainGroupIdArticles<TyreArticle>>

export interface RimsQuery {
	boltPattern?: number
	boltCircle?: number
	diameter?: number
	width?: number
	articleText?: string
	/**
	 * Enum `searchRimType`.
	 */
	rimType: number
	vehicleId?: string
	licensePlate?: string
	carModel?: string
	brandId?: number
	languageCode: string
}

export interface RimArticle extends ArticleModel {
	boltPattern: string
	ean: number
	recommendedPrice: number | null
	minimumQuantity: number
	articleText: string
	width: number
	diameter: number
	offset: number
	centerBore: number
	seatType: number
	maximumLoad: number
	isWinterApproved: boolean
	isOE: boolean
	isMatched: boolean
	isECE: boolean
	kitset: unknown | null
	axle: number
	hasRimCombination: boolean
	brandName: string
	modelId: number
	modelName: string
	parentColor: string | null
	color: string
	upstepNoteIds: unknown[]
	noteInfo: unknown | null
	points: number | null
}

export type RimsRes = ArticleStocks<DiameterArticles<RimArticle>> & {
	carInfo: CarInfo
}

export interface AcessoriesQuery {
	articleText: string | null
	accessoryType: number
	mainGroupId: number,
	diameter: number | null,
	width: number | null,
	aspectRatio: number | null,
}

export interface AccessoryArticle extends ArticleModel {
	ean: unknown | null
	recommendedPrice: number
	minimumQuantity: number
	piecesPerPackage: number
	articleText: string
	diameter: number | null
	thread: unknown | null
	length: string | null
	threadDiameter: unknown | null
	innerDiameter: unknown | null
	outerDiameter: unknown | null
	manufacturer: string | null
	hex: unknown | null
	seatType: unknown | null
	notes: unknown | null
	thickness: unknown | null
	autoCenterBore: unknown | null
	spacerCenterBore: unknown | null
	autoPCD: unknown | null
	spacerPCD: unknown | null
	quantityPackage: unknown | null
	measurementA: unknown | null
	measurementB: unknown | null
	measurementC: unknown | null
	measurementD: unknown | null
	measurementE: unknown | null
	preProgrammed: boolean
	vehicle: unknown | null
	points: number | null
}

export type Accessory = ArticleStocks<GroupNameArticles<AccessoryArticle>>

export interface AccessoryType {
	key: number
	value: string
}

export interface TpmsQuery {
	mainGroupId?: number
	includeTools?: boolean
	vehicleId?: string
	carBrand?: string
	carModel?: string
	carYear?: string
	articleText?: string
}

export interface TpmsArticle extends ArticleModel {
	ean: unknown | null
	recommendedPrice: number
	minimumQuantity: number
	piecesPerPackage: number
	articleText: string
	diameter: unknown | null
	thread: unknown | null
	length: unknown | null
	threadDiameter: unknown | null
	innerDiameter: unknown | null
	outerDiameter: unknown | null
	manufacturer: unknown | null
	hex: unknown | null
	seatType: unknown | null
	notes: unknown | null
	thickness: unknown | null
	autoCenterBore: unknown | null
	spacerCenterBore: unknown | null
	autoPCD: unknown | null
	spacerPCD: unknown | null
	quantityPackage: unknown | null
	measurementA: unknown | null
	measurementB: unknown | null
	measurementC: unknown | null
	measurementD: unknown | null
	measurementE: unknown | null
	preProgrammed: boolean
	vehicle: unknown | null
	points: number | null
}

export type TpmsRes = ArticleStocks<Articles<TpmsArticle>> & {
	hasIndirectTPMS: boolean
	carInfo: CarInfo
}

export interface WheelsQuery {
	vehicleId?: string
	licensePlate?: string
	carModel?: string
	brandId?: number
	languageCode: string
}

export interface WheelsArticle extends ArticleModel {
	boltPattern: string
	ean: number
	recommendedPrice: number | null
	minimumQuantity: number
	articleText: string
	width: number
	diameter: number
	offset: number
	centerBore: number
	seatType: number
	maximumLoad: number
	isWinterApproved: boolean
	isOE: boolean
	isMatched: boolean
	isECE: boolean
	kitset: unknown | null
	axle: number
	hasRimCombination: boolean
	brandName: string
	modelId: number
	modelName: string
	parentColor: string | null
	color: string | null
	upstepNoteIds: unknown[]
	noteInfo: string | null
	points: number | null
}

export interface WheelsRes {
	aluRims: DiameterArticles<WheelsArticle>
	steelRims: DiameterArticles<WheelsArticle>
	carInfo: CarInfo
}

export interface WheelsTyresQuery {
	vehicleId: string
	diameter: number
	width: number
	secondaryDiameter?: number
	secondaryWidth?: number
	/**
	 * Enum `tyreType`.
	 */
	tyreType: number
	languageCode: string
}

export type WheelsTyresRes = Articles<RimArticle> & {
	aspectRatio: number
	diameter: number
	mainGroupId: number
	mainGroupSorting: number
	width: number
}

export interface WheelsTpmsQuery {
	vehicleId: string
}

export interface WheelsTpms {
	hasTpms: boolean
	price: number
}

export interface AlternativeDimensionsQuery {
	customerId?: number
	subCustomerId?: number
	webUserId?: number
	width?: number
	aspectRatio?: number
	diameter?: number
	dimensions?: number[]
	articleText?: string
	tyreType: number
	minFourInLocalStock?: boolean
	loadIndex?: number
	excludeBrackets?: boolean
}

export interface AlternativeDimension {
	width: number
	aspectRatio: number
	diameter: number
	circumference: number
	difference: number
}
export interface WheelPackageExcelListQuery {
	emailAddress: string
	languageCode: string
}

export interface WheelPackagesQuery {
	packageNumber: string
	carbrandId: number
	carTypeName: string
	tyreType: number
	languageCode: string
}

export interface WheelPackageArticle {
	id: number
	articleText: string
	mainGroupId: number
	brandName: string
	patternModelName: string
	width: number
	aspectRatio: number
	diameter: number
	speedIndex: string
	loadIndex: string
	fuelEffiency: string
	wetGrip: string
	noiceClass: string
	noiceValue: number
	isWinterApproved: boolean
	quantityAvailable: number
	quantityExternal: number
	externalDeliveryTime: string
	externalStockCountryCode: string
	stopTime: string
	kitset: string
	imageId: number
	frictionRegion: number
}

export type WheelPackagesRes = Articles<WheelPackageArticle> & {
	id: number
	packageNumber: string
	carBrandName: string
	carTypeName: string
	description: string
	netPrice: number
	priceIncludeVat: number
	hasTPMS: boolean
	assembling: boolean
	isFrontBackCombination: boolean
	mainGroupIds: number[]
}

export interface WheelPackageTyreTypes {
  summerTyres: boolean
  winterTyres: boolean
}

export interface BatteriesQuery {
	voltage: number
	articleText: string
	brand: string
	fuelType: string
	model: string
	engineType: string
	engine: string
	year: string
}

export interface BatteryArticle extends ArticleModel {
	manufacturerArticleId: string
	subGroupName: string
	ean: number
	articleText: string
	ampereHours: number
	coldCrankingAmps: number
	polarity: number
	batteryBoxType: string
	batteryTypeName: string
	bottomFasteningType: string
	length: number
	width: number
	height: number
	recommendedPrice: number
	points: number | null
}

export type BatteriesRes = ArticleStocks<Articles<BatteryArticle>>

export type BatteryVoltage = [
	number,
	number,
	number,
]
