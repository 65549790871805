import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { fetchNewInvoices, putNewInvoicesValidatedPrinting } from 'utils/api/newInvoices'
import { statusHandler, thunkActions } from 'utils/utils'

export const getNewInvoices = createAsyncThunk('newInvoices/getNewInvoices', async () => fetchNewInvoices())
export const postNewInvoicesValidated = createAsyncThunk('newInvoices/postNewInvoices', async (payload) => putNewInvoicesValidatedPrinting(payload))
export const invoiceAction = thunkActions(getNewInvoices, postNewInvoicesValidated)

const newInvoicesSlice = createSlice({
	name: 'newInvoices',
	initialState: {
		status: 'idle',
		data: null,
		showNewInvoices: false
	},
	reducers: {
		setShowNewInvoices: (state, action) => {
			state.showNewInvoices = action.payload
		},
	},
	extraReducers: (builder) => {
	 	builder
	 		.addCase(getNewInvoices.fulfilled, (state, action) => {
	 			state.data = action.payload
	 		})
	 		.addCase(getNewInvoices.rejected, (state) => {
	 			state.data = null
	 		})
			 .addCase(postNewInvoicesValidated.fulfilled, (state, action) => {
				state.data = action.payload
			})
			.addCase(postNewInvoicesValidated.rejected, (state) => {
				state.data = null
			})
	 		.addMatcher(invoiceAction, statusHandler)
	 }
})
export const selectNewInvoiceStatus = state => state.user.newInvoices.status
export const selectNewInvoices = state => state.user.newInvoices.data
export const selectShowNewInvoices = state => state.user.newInvoices.showNewInvoices

export const { setShowNewInvoices } = newInvoicesSlice.actions

export default newInvoicesSlice.reducer