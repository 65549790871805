import './Footer.scss'
import React from 'react'
import dayjs from 'dayjs'
import { selectDomain, selectResellerConfig, selectSettings, selectSymbol } from 'features/app/settings/settingsSlice'
import { useSelector } from 'react-redux'
import classNames from 'classnames'
import DetailedFooter from './DetailedFooter/DetailedFooter'

export interface FooterProps {
	social?: boolean
	alignLeft?: boolean
	detailed?: boolean
}

const Footer: React.FC<FooterProps> = ({
	alignLeft = false,
	detailed = false
}) => {
	const domain = useSelector(selectDomain)
	const symbol = useSelector(selectSymbol)
	const resellerConfig = useSelector(selectResellerConfig)
	const settings = useSelector(selectSettings)

	return (
		<footer className={classNames('Footer', { 'alignLeft': alignLeft })}>
			{detailed &&
				<DetailedFooter />
			}

			<div className="footer-wrapper">
				<small>
					{domain && (
						<span>
							{domain.charAt(0).toUpperCase() + domain.slice(1)}
							<span className="text-spacer"> - </span>
						</span>
					)}

					{symbol && (
						<span>
							{symbol}
							<span className="text-spacer"> - </span>
						</span>
					)}

					{dayjs().year()}
					<span className="text-spacer"> - </span>
					{
						resellerConfig ?
							<span>{settings?.title}</span> :
							<a href="https://nordictyregroup.com/">Nordic Tyre Group</a>
					}
				</small>
			</div>
		</footer>
	)
}

export default Footer