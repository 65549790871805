import mainGroups from './enums/mainGroups'
import accessoryTypes from './enums/accessoryTypes'
import cloneDeep from 'lodash/cloneDeep'
import sortBy from 'lodash/sortBy'
import orderBy from 'lodash/orderBy'
import groupBy from 'lodash/groupBy'
import { createSelector } from '@reduxjs/toolkit'
import { searchCategoryChange } from './redux/commonActions'

/**
 * check if mainGroupId is summer 
 * @param {number} mainGroupId
 */
export const isSummerTyre = (mainGroupId) => {
	const summer = [
		mainGroups.summerTyrePassengerCar,
		mainGroups.summerTyreInch,
		mainGroups.summerTyreC
	]
	return summer.includes(mainGroupId)
}

/**
 * Check if mainGroupId is winter 
 * @param {number} mainGroupId
 */
export const isWinterTyre = (mainGroupId) => {
	const winter = [
		mainGroups.winterTyreFriction,
		mainGroups.winterTyreFrictionC,
		mainGroups.winterTyreCStuddable,
		mainGroups.winterTyreCStudded,
		mainGroups.winterTyreInch,
		mainGroups.winterTyreStuddable,
		mainGroups.winterTyreStudded
	]
	return winter.includes(mainGroupId)
}

/**
 * Check if mainGroupId is all season 
 * @param {number} mainGroupId
 */
export const isAllSeasonTyre = (mainGroupId) => {
	const allSeason = [
		mainGroups.allSeasonTyre,
		mainGroups.allSeasonLightTruck,
	]
	return allSeason.includes(mainGroupId)
}

/**
 * Check for friction winter tyre
 * @param {number} mainGroupId 
 * @returns 
 */
export const isWinterFrictionTyre = (mainGroupId) => {
	const tyres = [
		mainGroups.winterTyreFriction,
		mainGroups.winterTyreFrictionC,
		mainGroups.winterTyreInch
	]

	return tyres.includes(mainGroupId)
}

/**
 * Check for studded tyre
 * @param {number} mainGroupId 
 * @returns 
 */
export const isStuddedTyre = (mainGroupId) => {
	return mainGroupId === mainGroups.winterTyreStudded || mainGroupId === mainGroups.winterTyreCStudded
}

/**
 * Check for studdable tyre
 * @param {number} mainGroupId 
 * @returns 
 */
export const isStuddableTyre = (mainGroupId) => {
	return mainGroupId === mainGroups.winterTyreStuddable || mainGroupId === mainGroups.winterTyreCStuddable
}

/**
 * Check for C-tyretype
 * @param {number} mainGroupId 
 * @returns 
 */
export const isCTyre = (mainGroupId) => {
	const tyres = [
		mainGroups.summerTyreC,
		mainGroups.winterTyreFrictionC,
		mainGroups.winterTyreCStuddable,
		mainGroups.winterTyreCStudded
	]
	return tyres.includes(mainGroupId)
}

/**
 * Check for inch tyres
 * @param {number} mainGroupId 
 * @returns 
 */
export const isInchTyre = (mainGroupId) => {
	const tyres = [
		mainGroups.summerTyreInch,
		mainGroups.winterTyreInch
	]

	return tyres.includes(mainGroupId)
}

/**
 * Check if maingroup is rim
 * @param {number} mainGroupId 
 * @returns 
 */
export const isRim = (mainGroupId) => {
	const groups = [
		mainGroups.steelRim,
		mainGroups.steelRimLV,
		mainGroups.aluminumRim
	]

	return groups.includes(mainGroupId)
}

/**
 * Check if maingroup is accessory
 * @param {number} mainGroupId 
 * @returns 
 */
export const isAccessory = (mainGroupId) => {
	const groups = [
		mainGroups.accessoriesCar,
		mainGroups.accessoriesIndustry,
		mainGroups.accessoriesBattery,
		mainGroups.accessoriesOil,
		mainGroups.tpmsPcr,
		mainGroups.tpmsTbr,
		mainGroups.tubes,
		mainGroups.tubesIndustry,
		mainGroups.tubesMoto
	]
	return groups.includes(mainGroupId)
}

/**
 * Check if mangroup is battery
 * @param {number} mainGroupId 
 * @returns 
 */
export const isBattery = (mainGroupId) => {
	return mainGroupId === mainGroups.battery
}

export const isOil = mainGroupId => {
	return mainGroupId === mainGroups.oil
}

/**
 * Check if maingroup is tyre
 * @param {number} mainGroupId 
 * @returns 
 */
export const isTyre = (mainGroupId) => {
	return mainGroupId < 100
}

export const getAccessoryType = (id) => {
	return Object.keys(accessoryTypes).find(key => accessoryTypes[key] === id)
}

/**
 * Get articles from all collections in object or from array
 * @param {*} collection 
 * @returns 
 */
export const getAllArticles = (collection) => {
	if (Array.isArray(collection))
		return getArticles(collection)
	else
		return getArticlesFromObject(collection)
}

/**
 * Get articles from collection, e.g. from ToOrder property in result
 * @param {*} collection 
 * @returns 
 */
export const getArticles = (collection) => {
	const articles = []
	if (!collection) return articles

	for (const value of collection) {
		if (Array.isArray(value.articles)) articles.push(...value.articles)
		else articles.push(value)
	}

	return articles
}

/**
 * Get all articles from object
 * @param {object} object - Eg. Object with InStock, ToOrder and so on
 * @returns 
 */
export const getArticlesFromObject = (object) => {
	let articles = []

	if (!object)
		return articles

	Object.keys(object).forEach((key) => {
		if (object[key] && Array.isArray(object[key])) {
			articles = articles.concat(
				object[key].reduce((acc, value) => {
					if (value && value.articles) {
						return acc.concat(value.articles)
					}
					return acc
				}, [])
			)
		}
	})

	return articles
}

/**
 * Filter collection by ids
 * @param {Array} collection 
 * @param {Array} articleIds - Array with numbers that should be visible
 * @returns 
 */
export const filterArticles = (collection, articleIds) => {
	if (!collection || !articleIds)
		return null

	const clonedCollection = cloneDeep(collection)

	Object.keys(clonedCollection).forEach((key) => {
		if (clonedCollection[key] && Array.isArray(clonedCollection[key])) {
			clonedCollection[key].forEach(group => {
				group.articles = group.articles?.filter(a => articleIds.includes(a.id)) ?? group.articles
			})
		}
	})

	return clonedCollection
}

/**
 * Filter collection by ids
 * @param {Array} collection 
 * @param {Array} articleIds - Array with numbers that should be visible
 * @returns 
 */
export const getCountryName = () => {

}

/**
 * Check if userroles has any of provided roles
 * @param {Array} userRoles 
 * @param {any} roles 
 * @returns 
 * @deprecated Use `useUserRoles` instead.
 */
export const hasAnyRole = (userRoles, ...roles) => {
	if (!userRoles || !roles)
		return false

	return userRoles.some(r => roles.indexOf(r) !== -1)
}

/**
 * Sort result by property
 * @param {*} state 
 * @param {*} property 
 * @param {*} ascending 
 * @returns 
 */
export const sortArticles = (collection, property, ascending) => {
	if (!collection || !property)
		return null

	ascending = ascending || false

	const sortDirection = ascending ? 1 : -1

	const clonedCollection = cloneDeep(collection)
	Object.keys(clonedCollection).forEach((key) => {
		if (clonedCollection[key] && Array.isArray(clonedCollection[key])) {
			if (property === 'name') {
				clonedCollection[key].map(group => group.articles = sortBy(group.articles, [
					a => a.brandName,
					a => a.patternName,
					a => a.modelName
				]))
			} else if (property === 'stock') {
				clonedCollection[key].map(group => group.articles = sortBy(group.articles, [
					a => a.quantityCustomerStock * sortDirection,
					a => a.quantityAvailable * sortDirection,
					a => (a.quantityExternal && a.externalDeliveryTime && a.externalDeliveryTime.length > 0 ? 4 : a.quantityExternal) < 4,
					a => {
						if (!a.externalDeliveryTime)
							return 0

						var day = a.externalDeliveryTime.split('-')
						return Number(day[0])
					},
					a => a.quantityExternal * sortDirection
				]))
			} else {
				clonedCollection[key].map(group => group.articles = orderBy(group.articles, [property], [ascending ? 'asc' : 'desc']))
			}
		}
	})

	return clonedCollection
}

/**
 * Sort premade wheelpackages
 * @param {*} collection 
 * @param {*} property 
 * @param {*} ascending 
 * @returns 
 */
export const sortPremadeWheelCollection = (collection, property, ascending) => {
	if (!collection || !property)
		return null

	ascending = ascending || false

	let clonedCollection = cloneDeep(collection)
	if (property === 'name')
		property = 'carTypeName'

	clonedCollection = orderBy(collection, [property], [ascending ? 'asc' : 'desc'])

	return clonedCollection
}

export const getSortSettings = (sortBy, showRetailPrice, ascendingBy = true, selectedSort = '') => {
	let sortName = sortBy
	let ascending = ascendingBy

	const priceHeaders = ['netPrice', 'price', 'priceIncludingVat']

	if (priceHeaders.indexOf(sortBy) >= 0)
		sortName = 'price'

	if (sortName === selectedSort) {
		ascending = !ascending
	} else {
		ascending = sortName.indexOf('High') < 0 && sortBy !== 'stock'
	}

	var settings = {
		name: sortName,
		property: getSortByProperty(sortName, showRetailPrice),
		ascending: ascending
	}

	return settings
}

export const getSortByProperty = (sortBy, showRetailPrice) => {
	if (sortBy.indexOf('price') >= 0 && showRetailPrice)
		return 'retailPrice'
	else if (sortBy.indexOf('price') >= 0)
		return 'netPrice'
	else
		return sortBy
}

/**
 * Check if search result has empty collections
 * @param {object} - Search result to check
 * @returns 
 */
export const hasOnlyEmptyCollections = (object) => {
	let result = true
	Object.keys(object).forEach((key) => {
		if (object[key] && Array.isArray(object[key])) {
			const hasResult = object[key].some(c => c.articles.length > 0)

			if (hasResult)
				result = false
		}
	})

	return result
}

export const createCombinationResult = (articles) => {
	const allArticles = getAllArticles(articles)
	const grouped = groupBy(allArticles, r => (r.modelId || r.patternName))

	let groupedCollections = []

	for (let key of Object.keys(grouped)) {
		let groupedArticles = grouped[key]

		groupedArticles = sortBy(groupedArticles, ['modelName', 'patternName', 'axle'])

		groupedCollections.push({
			modelId: key,
			modelName: groupedArticles[0].modelName || groupedArticles[0].patternName,
			articles: groupedArticles
		})
	}

	return groupedCollections
}

/**
 * Create result selector
 * @param {*} selectResult - result selector
 * @param {*} selectFilterIds - filter selector
 * @param {*} selectSortBy - Optional: sort by selector
 * @param {*} selectAscending - Optional: sort by ascending selector
 * @param {*} selectShowCombination - Optional: create combination of result
 */
export const createResultSelector = (selectResult, selectFilterIds, selectSortBy = () => null, selectAscending = () => null, selectShowCombination = () => null) => {
	return createSelector([selectResult, selectFilterIds, selectSortBy, selectAscending, selectShowCombination], (result, filterIds, sortBy, ascending, showCombination) => {
		const processedResult = !filterIds ? result : filterArticles(result, filterIds)

		if (showCombination)
			return createCombinationResult(processedResult)
		else
			return !sortBy ? processedResult : sortArticles(processedResult, sortBy, ascending)
	})
}

/**
 * Like `createResultSelector`, but a lighter version
 * that only requires articles to be provided
 * and does not need `groups`.
 */
export const createFilteredDataSelector = (
	selectData,
	selectFilterIds,
) => {
	return createSelector([
		selectData,
		selectFilterIds,
	], (
		data,
		filterIds,
	) => {
		if (filterIds === null) return data
		return data?.filter(article => filterIds.includes(article.id))
	})
}

/**
 * Get tracking from order
 * @param {*} trackingUrls 
 * @param {*} order 
 */
export const getTracking = (trackingUrls, order) => {
	return trackingUrls && trackingUrls.find((tracking) => {
		return order.trackingUrlId ? tracking.trackingUrlId === order.trackingUrlId : tracking.wayOfDeliveryId === order.wayOfDeliveryId
	})
}

export const hasTracking = (trackingUrls, order) => {
	const tracking = getTracking(trackingUrls, order)
	return tracking && tracking.url.indexOf('[[number]]') && (order.parcelNumber || order.parcelNumbers)
}

export const trackingUrl = (trackingUrls, order, currentLanguage) => {
	const tracking = getTracking(trackingUrls, order)
	return tracking.url.replace('[[number]]', (order.parcelNumber || order.parcelNumbers)).replace('[[lang]]', currentLanguage)
}

/**
 * Check for category change 166422
 * @param {string} category 
 * @returns 
 */
export const isSearchCategory = (vehicleType, category) => {
	const typeChange = searchCategoryChange()
	return (action) => {
		return action.type === typeChange.type && action.payload.category === category && action.payload.vehicleType === vehicleType
	}
}

export const isPreviousSearchCategory = (vehicleType, category) => {
	const typeChange = searchCategoryChange()
	return (action) => {
		return action.type === typeChange.type && action.payload.previousCategory === category && action.payload.previousVehicleType === vehicleType
	}
}


export const translateKitsetText = (text, t) => {
	return text.replace('CenterRing', t('kitsets.centerRing'))
		.replace('NoRings', t('kitsets.noRings'))
		.replace('FlexBolt', t('kitsets.flexBolt'))
		.replace('NoBoltNut', t('kitsets.noBoltNut'))
		.replace('Nut', t('kitsets.nut'))
		.replace('Bolt', t('kitsets.bolt'))
}

export const filterHidden = (filter) => {
	return !filter.hide
}

export const orderStatus = (item, t) => {
	if (item.isPrepayment)
		return t('common.prepayment')
	if (item.isPreSeasonOrder)
		return t('orderPage.preSeason')
	if (item.realization)
		return t('orderPage.realization')

	switch (item.subStatus) {
		case 'B':
			return t('orderSubStatus.blanket')
		case 'O':
			return t('orderSubStatus.offer')
		case 'S':
			return t('orderSubStatus.selfBilling')
		case 'R':
			return t('orderSubStatus.reservation')
		default:
			return '-'
	}
}

export const languageArray = (languageConfig) => {
	var newData = []
	const a = Object.keys(languageConfig).filter(key => languageConfig[key])
	for (let i = 0; i < a.length; i++) {
		if (a[i].indexOf('display') === 0) {
			newData.push(a[i].replace('display', '').toLowerCase())
		}
	}
	return newData
}

/**
 * Clean criteria before dispatch to API
 * @param {object} criteria 
 * @returns 
 */
export const cleanCriteria = (criteria) => {
	let cleanCriteria = {}
	for (const key in criteria) {
		cleanCriteria[key] = criteria[key] !== 'all' ? criteria[key] : ''
	}

	return cleanCriteria
}

export const getStockQuantity = (
	article,
) => {
	let quantity = null
	
	if (article.quantityCustomerStock) {
		quantity = article.quantityCustomerStock
	}
	else if (article.quantityIncoming && article.quantityIncoming > 0 && article.quantityAvailable <= 0) {
		quantity = article.quantityIncoming
	}
	else if (article.quantityAvailable && article.quantityAvailable > 0) {
		quantity = article.quantityAvailable
	}
	else if (article.quantityExternal && article.quantityExternal > 0) {
		quantity = article.quantityExternal
	}

	return quantity
}

export const isLatakko = (
	countryCode,
) => {
	if (
		countryCode === 'LT' ||
		countryCode === 'LV' ||
		countryCode === 'EE' ||
		countryCode === 'ET' // Ethiopia
	) return true
	return false
}

export const getArticleImageId = article => {
	return article.imageId
		? `${process.env.REACT_APP_API_URL}/api/ArticleImages/${article.imageId}`
		: null
}

export const createAddToCartPayload = (article, quantity) => {
	const data = {
		licensePlate: null,
		tecDocId: null,
		articleId: article.id ?? null,
		isMatched: article.isMatched ?? null,
		kitset: article.kitset ?? null,
		brandId: article.brandId ?? null,
		carModel: article.carModel ?? null,
		quantity,
	}

	const payload = {
		inStock: [data],
		incoming: [],
		toOrder: []
	}

	return payload
}

/**
 * Creates an input handler that handles:
 * -  Text input events
 * -  Checkbox input events
 * -  Custom input parameters in the form of (value, name)
 */
export const createFormInputEventHandler = onFormValueChange => {
	const onFormInput = (e, inputName) => {
		// If the provided parameters do not resemble a browser event,
		// then assume that the provided parameters are: (value, name).
		if (!e.target) {
			const value = e
			const name = inputName
			onFormValueChange(name, value)
			return
		}

		const { checked, type, name } = e.target
		let value = type === 'checkbox' ? checked : e.target.value

		onFormValueChange(name, value)
	}

	return onFormInput
}

export const isFieldsEmpty = (object, propertyNames) => {
	for (let propertyName of propertyNames) {
		if (object[propertyName]) return false
	}

	return true
}

/**
 * Determines whether `priceCalculationMethod` is the simple pricing method
 * or the other one.
 */
export const isSimplePricingMethod = (priceCalculationMethod) => {
	return priceCalculationMethod !== 1
}

/**
 * Validates cart data in `Cart`.
 */
export const isStuddingValid = (cartData) => {
	if (!Array.isArray(cartData.rows)) return false

	for (const {
		articles,
	} of cartData.rows) {
		if (!Array.isArray(articles)) continue

		for (const {
			isStuddable,
			studding,
		} of articles) {
			// If studding is not set...
			if (isStuddable && studding === null) return false
		}
	}

	return true
}

/**
 * Get backend URL depending on development or production
 * @param {string} url 
 * @returns {string} Backend URL
 */
export const getBackendUrl = (url) => {
	if (process.env['NODE_ENV'] === 'development')
		return `http://localhost:63540${url}`
	else
		return url
}