import './MiniCart.scss'
import React, { useEffect, RefObject, useRef } from 'react'
import MiniCartCard from './MiniCartCard/MiniCartCard'
import { fetchMiniCart, selectCart, updateRow } from '../checkout/checkoutSlice'
import { useDispatch, useSelector } from 'react-redux'
import { miniCartWindow, selectShowMiniCart } from './miniCartSlice'

import { useTranslation } from 'react-i18next'
import Icon from '@mdi/react'
import { mdiCartVariant } from '@mdi/js'
import { useSettings } from 'utils/hooks/useSettings'
import { useNavigate } from 'react-router-dom'

export default function MiniCart() {
	const { i18n } = useTranslation()
	const navigate = useNavigate()
	const dispatch = useDispatch()
	const { goDirectlyToCart } = useSettings()
	const cardRef: RefObject<HTMLDivElement> = useRef<HTMLDivElement>(null)
	const miniRef: RefObject<HTMLDivElement> = useRef<HTMLDivElement>(null)

	const cartData = useSelector(selectCart)
	const	languageCode = i18n.language

	const show = useSelector(selectShowMiniCart)

	const toggleMiniCartHandler = () => {
		// go directly to cart if settings is set
		if (goDirectlyToCart) {
			return navigate('/cart')
		} 

		// Open mini cart
		dispatch(miniCartWindow(!show))
	}

	const closeMiniCartHandler = () => {
		dispatch(miniCartWindow(false))
	}

	const removeHandler = (id: number) => {
		// @ts-ignore // Something werid with the dispatch function when its not typed slice
		dispatch(updateRow({ 
			rowId: id, 
			criteria: [{
				op: 'replace',
				path: 'quantity',
				value: 0
			}], 
			languageCode: languageCode, 
			lastChanged: cartData.lastChanged 
		}))
	}
	
	// TODO: article count should be on backend
	const articleCount = () => {
		var qty = 0
		if (cartData.rows.length > 0) {
			const rows = cartData.rows
			for (let i = 0; i < rows.length; i++) {
				const articles = rows[i].articles
				for (let x = 0; x < articles.length; x++) {
					qty += articles[x].quantity
				}
			}
		}
		return qty
	}
	
	useEffect(() => {
		if (languageCode) {
			// @ts-ignore // Something werid with the dispatch function when its not typed slice
			dispatch(fetchMiniCart(languageCode))
		}
	}, [languageCode, show, dispatch])

	// Checks if pressing outside the MiniCart wrapper
	useEffect(() => {
		const checkIfClickedOutside = (event: { target: any }) => {
			// If the menu is open and the clicked target is not within the menu,
			// then close the menu
			
			if (show && !cardRef?.current?.contains(event.target) && !miniRef?.current?.contains(event.target)) {
				dispatch(miniCartWindow(false))
			}
		}

		document.addEventListener('mousedown', checkIfClickedOutside)

		return () => {
			// Cleanup the event listener
			document.removeEventListener('mousedown', checkIfClickedOutside)
		}

	}, [show, dispatch])

	return (
		<div className="MiniCart-wrapper">
			<div className="MiniCart" onClick={toggleMiniCartHandler} ref={miniRef}>

				<Icon path={mdiCartVariant} size='2.1875rem' />
				{(cartData && cartData.rows) && 
					<div className="cart-qty">
						{articleCount()}
					</div>
				}
			</div>

			{(show && cartData) &&
				<div className="miniCartCard-wrapper" ref={cardRef}>
					<MiniCartCard
						onClose={ closeMiniCartHandler }
						onRemove={ removeHandler }
						articleCount={ articleCount() }
					/>
				</div>
			}

		</div>
	)
}
