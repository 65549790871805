import './ArticleLinkWindow.scss'
import React, { useState } from 'react'
import { Button, LoadingOverlay, Modal } from '@barnsten-it/gummidata-react-components'
import { closeWindow, selectShowWindow, selectResult } from '../articleLinkSlice'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import ArticleLinkView from 'components/ListViews/ArticleLinkView/ArticleLinkView'
import { addToCart, fetchCart, selectCart } from 'features/cart/checkout/checkoutSlice'
import { useNotifications } from '../../../../utils/hooks/useNotifications'
import { usePrevious } from 'utils/utils'


const ArticleLinkWindow = () => {
	const dispatch = useDispatch()
	const showWindow = useSelector(selectShowWindow)
	const result = useSelector(selectResult)
	const cart = useSelector(selectCart)
	const prevCart = usePrevious(cart)
	const { t, i18n } = useTranslation()
	const { dispatchNotification } = useNotifications()
	const [quantities, setQuantities] = useState([])
	const [loading, setLoading] = useState(false)
	const [parentArticle, setParentArticle] = useState([null])

	const onClose = () => {
		dispatch(closeWindow())
	}

	const handleQuantityChange = (article, quantity) => {
		const current = [...quantities]
		let existing = current.find(a => a.id === article.id && a.minimumQuantity === article.minimumQuantity)

		if (existing) {
			if (!quantity || quantity <= 0) {
				const index = current.findIndex(a => a.id === article.id && a.minimumQuantity === article.minimumQuantity)
				current.splice(index, 1)
			} else
				existing.quantity = quantity
		} else {
			current.push({
				...article,
				quantity
			})
		}

		setQuantities(current)
	}

	const onAddToCart = async () => {
		const articles = quantities.map(a => ({
			articleId: a.id,
			quantity: a.quantity,
			parentId: parentArticle.id,
		}))

		const payload = {
			inStock: articles,
			incoming: [],
			toOrder: []
		}

		setLoading(true)
		const result = await dispatch(addToCart(payload))
		setLoading(false)
		
		if (!result.error) {
			dispatch(fetchCart(i18n.language))
			onClose()
		} else {
			dispatchNotification('errorTryAgain')
		}
	}

	React.useEffect(() => {
		if (cart?.rows?.length > 0 && prevCart !== cart) {
			setParentArticle(cart.rows[cart.rows.length - 1].articles[0])
		}		
	}, [cart, prevCart])

	if (!showWindow)
		return null

	return (
		<div className="ArticleLinkWindow">
			<Modal onClose={onClose} label={t('articleLink.caption')} closeOutside={false}>
				{loading && <LoadingOverlay />}
				<ArticleLinkView result={result} onQuantityChange={handleQuantityChange} quantities={quantities} />
				<div className="row center-xs buttons">
					<Button label={t('articleLink.skip')} onClick={onClose} />
					<Button label={t('articleLink.add')} onClick={onAddToCart} color="secondary" />
				</div>
			</Modal>
		</div>
	)
}

export default ArticleLinkWindow
