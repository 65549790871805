import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { fetchInvoiceHistory } from 'utils/api/invoiceHistoryAPI'
import { statusHandler, thunkActions } from 'utils/utils'

export const getInvoiceHistory = createAsyncThunk('invoiceHistory/getInvoiceHistory', async (payload) => fetchInvoiceHistory(payload))
export const invoiceAction = thunkActions(getInvoiceHistory)

const invoiceHistorySlice = createSlice({
	name: 'invoiceHistory',
	initialState: {
		status: 'idle',
		data: null
	},
	extraReducers: (builder) => {
	 	builder
	 		.addCase(getInvoiceHistory.fulfilled, (state, action) => {
	 			state.data = action.payload
	 		})
	 		.addCase(getInvoiceHistory.rejected, (state) => {
	 			state.data = null
	 		})
	 		.addMatcher(invoiceAction, statusHandler)
	 }
})
export const selectInvoiceHistoryStatus = state => state.user.invoiceHistory.status
export const selectInvoiceHistory = state => state.user.invoiceHistory.data

export default invoiceHistorySlice.reducer