import i18next from 'i18next'
import ChainedBackend from 'i18next-chained-backend'
import HttpBackend from 'i18next-http-backend'
import { initReactI18next } from 'react-i18next'
import { getLocalStorageItem } from 'utils/utils'

const i18nStartFunciton = (startLanguage) => {
	i18next
		.use(ChainedBackend)
		.use(initReactI18next)
		.init({
			fallbackLng: getLocalStorageItem('languageCode') || startLanguage,
			debug: false,
			react: {
				useSuspense: false,
			},
			parseMissingKeyHandler: (property) => {
				// if (process.env.NODE_ENV !== 'development') return ''

				return property
			},
			backend: {
				backends: [
					HttpBackend,
				],
				backendOptions: [{
					requestOptions: {
						credentials: 'include',
					},
					loadPath: (_, namespace) => {

						/**
						 * Select the webTexts namespace
						 */
						if (namespace[0].startsWith('webtext')) {
							const ns = namespace[0].replace('webtext.', '')
							return process.env.REACT_APP_API_URL + `/api/WebTexts/?languageCode={{lng}}&ns=${ns}`
						} 
						
						/**
						 * Select the staticTexts namespace
						 */
						if (namespace[0].startsWith('staticTexts')) {
							const ns = namespace[0].replace('staticTexts.', '')

							// public folder of this project
							return `/staticTexts/{{lng}}/${ns}.json`
						}	
						
						/**
						 * Default to basic translations
						 */
						return process.env.REACT_APP_API_URL + '/api/Language/?languageCode={{lng}}&ns={{ns}}'
					}
				}]
			}
		})
}

export default i18nStartFunciton
